@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700,900");

body {
	margin: 0;
	padding: 0;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	background-color: #ffffff; /* f0f0f0 */
}

html {
	overflow-x: hidden;
}

/* Body is set to hidden when a dropdown is open */
body {
	overflow: visible !important;
}

.infoBox {
	width: auto !important;
}
::-webkit-scrollbar {
	width: 15px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background-image: linear-gradient(#c1c1c1, #c1c1c1);
	border-radius: 13px;
}
::-webkit-scrollbar-thumb:hover {
	background-image: linear-gradient(#9e9e9e, #9e9e9e);
	border-radius: 13px;
}

/* Expand widget to take up entire right screen */
.leaflet-top.leaflet-right {
	bottom: 0px;
}
.leaflet-control-layers-expanded {
	border-radius: 0px;
	position: absolute;
	margin: 0px !important;
	padding: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 227px;
	overflow: auto;
	background-color: rgba(100, 100, 100, 0.5);
}

/* Layer list background, text */
.leaflet-control-layers-list {
	background-color: #ebe7da;
	text-shadow: none;
}
.leaflet-control-layers-list label {
	font-size: 11px;
	margin: 0px;
}
.leaflet-control-layers-overlays label {
	font-weight: bold;
}
.leaflet-control-layers-overlays span.expandable {
	cursor: pointer;
}

/* Collapsible sections */
.legendlayers-header {
	margin: 0px;
	padding: 3px;
	border-top: 1px solid #bba;
	background: linear-gradient(
		to bottom,
		rgba(232, 228, 216, 1) 0%,
		rgba(125, 120, 107, 1) 100%
	);
	font-size: 11px;
	cursor: pointer;
}
.legendlayers-section {
	padding: 7px;
}

/* Top header & minimize button */
.legendlayers-top-header {
	border: 1px solid black;
	margin: 0px;
	font-size: 12px;
	font-weight: normal;
	text-align: center;
	background: linear-gradient(
		to bottom,
		rgba(150, 150, 150, 0.2) 0%,
		rgba(200, 200, 200, 0.3) 100%
	);
	color: black;
	text-shadow: 1px 1px 3px #ddd;
}
.legendlayers-top-header,
.legendlayers-header,
.leaflet-control-layers-base,
.leaflet-control-layers-overlays,
.legendlayers-section.leaflet-control-layers-list,
ul.legend,
.leaflet-control-layers-expanded
	.legendlayers-section.leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-base,
.leaflet-control-layers-expanded .leaflet-control-layers-overlays,
.leaflet-control-layers-expanded
	.legendlayers-section.leaflet-control-layers-list,
.leaflet-control-layers-expanded ul.legend {
	display: none;
}
.leaflet-control-layers-expanded .legendlayers-top-header,
.leaflet-control-layers-expanded .legendlayers-header,
.leaflet-control-layers-expanded .leaflet-control-layers-base.expanded,
.leaflet-control-layers-expanded .leaflet-control-layers-overlays.expanded,
.leaflet-control-layers-expanded
	.legendlayers-section.leaflet-control-layers-list.expanded,
.leaflet-control-layers-expanded ul.legend.expanded {
	display: inherit;
}
.legendlayers-top-header a {
	float: left;
	display: inline-block;
	width: 13px;
	height: 13px;
	margin: 2px;

	border: 1px solid #808080;
	border-radius: 3px;
	background-color: #ccc;

	text-align: center;
	line-height: 13px;
	text-decoration: none;
	color: black;
}

/* Legend color patches & labels */
ul.legend {
	list-style-type: none;
	margin: 0px;
	padding-left: 1em;
}
li.legend span.legend-color {
	display: inline-block;
	width: 9px;
	height: 9px;
	margin-right: 1em;
}
li.legend img.legend-image {
	margin-right: 1em;
}
li.legend span.legend-title {
	font-weight: normal;
}

.leaflet-top .ctrl:hover,
.leaflet-top .ctrla:hover,
.leaflet-top .ctrlb:hover {
	background-color: #f4f4f4;
}
.leaflet-touch .leaflet-top .ctrla,
.leaflet-touch .leaflet-top .ctrlb,
.leaflet-touch .leaflet-top .ctrl {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.leaflet-top .ctrla {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	background-position: 3px;
	background-repeat: no-repeat;
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	cursor: pointer;
}

.leaflet-top .ctrlb {
	background-color: #fff;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	background-position: 3px;
	background-repeat: no-repeat;
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;
	cursor: pointer;
	border: 2px solid rgba(0, 0, 0, 0.2);
}

.leaflet-top .globe {
	background-image: url("./globe.png");
}

.leaflet-top .loc {
	background-image: url("./locate.png");
}

/* MARKER CLUSTERS */
.marker-cluster-red {
	background-color: rgba(207, 56, 45, 0.3);
}
.marker-cluster-red div {
	background-color: rgba(207, 56, 45, 0.6);
}
.marker-cluster-orange {
	background-color: rgba(239, 141, 47, 0.3);
}
.marker-cluster-orange div {
	background-color: rgba(239, 141, 47, 0.6);
}
.marker-cluster-green {
	background-color: rgba(109, 164, 46, 0.3);
}
.marker-cluster-green div {
	background-color: rgba(109, 164, 46, 0.6);
}
.marker-cluster-teal {
	background-color: rgba(49, 162, 204, 0.3);
}
.marker-cluster-teal div {
	background-color: rgba(49, 162, 204, 0.6);
}
.marker-cluster-purple {
	background-color: rgba(194, 78, 177, 0.3);
}
.marker-cluster-purple div {
	background-color: rgba(194, 78, 177, 0.6);
}
.marker-cluster-darkred {
	background-color: rgba(150, 46, 52, 0.3);
}
.marker-cluster-darkred div {
	background-color: rgba(150, 46, 52, 0.6);
}
.marker-cluster-darkblue {
	background-color: rgba(9, 91, 151, 0.3);
}
.marker-cluster-darkblue div {
	background-color: rgba(9, 91, 151, 0.6);
}
.marker-cluster-lime {
	background-color: rgba(113, 120, 37, 0.3);
}
.marker-cluster-lime div {
	background-color: rgba(113, 120, 37, 0.6);
}
.marker-cluster-darkpurple {
	background-color: rgba(89, 52, 102, 0.3);
}
.marker-cluster-darkpurple div {
	background-color: rgba(89, 52, 102, 0.6);
}
.marker-cluster-royalblue {
	background-color: rgba(64, 100, 112, 0.3);
}
.marker-cluster-royalblue div {
	background-color: rgba(64, 100, 112, 0.6);
}
.marker-cluster-peach {
	background-color: rgba(254, 136, 120, 0.3);
}
.marker-cluster-peach div {
	background-color: rgba(254, 136, 120, 0.6);
}
.marker-cluster-yellow {
	background-color: rgba(248, 202, 133, 0.3);
}
.marker-cluster-yellow div {
	background-color: rgba(248, 202, 133, 0.6);
}
.marker-cluster-lightgreen {
	background-color: rgba(183, 242, 114, 0.3);
}
.marker-cluster-lightgreen div {
	background-color: rgba(183, 242, 114, 0.6);
}
.marker-cluster-skyblue {
	background-color: rgba(140, 213, 255, 0.3);
}
.marker-cluster-skyblue div {
	background-color: rgba(140, 213, 255, 0.6);
}
.marker-cluster-pink {
	background-color: rgba(252, 140, 230, 0.3);
}
.marker-cluster-pink div {
	background-color: rgba(252, 140, 230, 0.6);
}
.marker-cluster-lightred {
	background-color: rgba(229, 124, 134, 0.3);
}
.marker-cluster-lightred div {
	background-color: rgba(229, 124, 134, 0.6);
}
.marker-cluster-gray {
	background-color: rgba(164, 162, 165, 0.3);
}
.marker-cluster-gray div {
	background-color: rgba(164, 162, 165, 0.6);
}
.marker-cluster-black {
	background-color: rgba(47, 45, 48, 0.3);
}
.marker-cluster-black div {
	background-color: rgba(47, 45, 48, 0.6);
}

.leaflet-control-geosearch {
	position: absolute !important;
	left: 35% !important;
	width: 300px !important;
}

.file-drop {
	/* relatively position the container bc the contents are absolute */
	position: relative;
	height: 100px;
	width: 100%;
}

.file-drop > .file-drop-target {
	/* basic styles */
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 2px;
	/* --------------- */

	/* horizontally and vertically center all content */
	display: flex;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;

	flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;

	align-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;

	justify-content: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;

	align-content: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;

	text-align: center;
	/* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
	/* overlay a black mask when dragging over the frame */
	border: none;
	background-color: rgba(0, 0, 0, 0.65);
	box-shadow: none;
	z-index: 50;
	opacity: 1;
	/* --------------- */

	/* typography */
	color: white;
	/* --------------- */
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
	/* turn stuff orange when we are dragging over the target */
	color: #fff;
	box-shadow: 0 0 13px 3px #8cddff;
}

.waterleveldetailmap-container {
	height: 150px;
	width: 100%;
	border: 1px solid lightgray;
}

.editmap-container {
	height: 300px;
	width: 100%;
	border: 1px solid lightgray;
}

#contourContainer {
	border: 1px solid black;
	padding: 2px;
	background-color: white;
}

#contourSelect {
	width: 100%;
}

.leaflet-popup {
	min-width: 300px;
	max-width: 500px;
	/* border: 1px solid black; */
}

.leaflet-popup-content-wrapper {
	border-radius: 0px;
}

.leaflet-popup-content {
	/* max-width: 400px; */
	/* max-height: 250px; */
	/* min-width: 490px; */
	height: 371px;
	margin-top: 20px;
	margin-bottom: 5px;
	margin-left: 5px;
	margin-right: 5px;
}

.leaflet-popup-content-chart {
	height: 700px;
	min-width: 500px;
}
#wmsiden {
	width: 100%;
	height: 100%;
}
